export const formatDateDB = (dt) => {
    return dt.toISOString()
}

export const formatDateNoTime = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formattedDate;
  };

export const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

export const adjustToLocalDate = (date) => {
  const localDate = new Date(date);
  localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());
  return localDate;
};

  export const parseLocalDate = (dateString) => {
    // Split the date string into components (assuming 'YYYY-MM-DD' format)
    const [year, month, day] = dateString.split('-');
    // Create a new Date in local time
    return new Date(year, month - 1, day);
}